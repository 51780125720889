var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"thread-item",class:{ 'thread-item--opened': _vm.opened },on:{"click":function($event){return _vm.$emit('toggle')}}},[_c('div',{staticClass:"dropdown-wrapper"},[_c('dropdownIcon',{staticClass:"dropdown"})],1),_c('div',{staticClass:"content-wrapper"},[_c('div',{staticClass:"header"},[_c('div',{staticClass:"left"},[_c('div',{staticClass:"property property--subject",domProps:{"innerHTML":_vm._s(_vm.item.subject)}}),_c('div',{staticClass:"property property--from"},[_vm._v("From: "+_vm._s(_vm.item.email_from))]),_c('div',{staticClass:"property property--to"},[_vm._v("to "+_vm._s(_vm.item.email_to))])]),_c('div',{staticClass:"right"},[_c('div',{staticClass:"property property--date"},[_vm._v(" "+_vm._s(_vm.item.date)+" ")]),_c('div',{staticClass:"actions"},[(_vm.item.actions)?[_vm._l((_vm.item.actions),function(btn){return [(_vm.buttons[btn])?_c('div',{key:_vm.buttons[btn].id,staticClass:"actions__item-wrapper",attrs:{"title":_vm.buttons[btn].title,"name":`thread__${_vm.item.id}__action-item__${_vm.buttons[btn].id}`},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.handleAction(_vm.buttons[btn].type)}}},[_c(_vm.buttons[btn].component,_vm._g(_vm._b({tag:"component",staticClass:"actions__item",class:[
                    'actions__item--' + _vm.buttons[btn].type,
                    _vm.buttons[btn].class,
                    {
                      disabled: _vm.item.disabled,
                    },
                  ],attrs:{"is-ack":_vm.item.is_ack}},'component',_vm.buttons[btn].props,false),_vm.buttons[btn].events)),(_vm.buttons[btn].modal)?_c(_vm.buttons[btn].modal,{tag:"component",attrs:{"type":_vm.buttons[btn].type,"to":`thread__${_vm.item.id}__action-item__${_vm.buttons[btn].id}`}}):_vm._e()],1):_vm._e()]})]:_vm._e()],2),_c('div',{staticClass:"flex justify-end"},[(_vm.item.attachments && _vm.item.attachments.length > 0)?_c('button',{staticClass:"p-2 relative rounded-lg hover:bg-gray-100 flex items-center gap-2 font-semibold cursor-pointer",on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.showActionModal(_vm.item, 'email-trail-attachments')}}},[_c('font-awesome-icon',{staticClass:"text-lg text-blue-600",attrs:{"icon":"link"}}),_c('div',{staticClass:"rounded-full bg-blue-600 text-xs p-0.5 absolute -top-1 left-5 text-white",staticStyle:{"min-width":"20px","min-height":"20px"}},[_vm._v(" "+_vm._s(_vm.checkAttachmentsLength(_vm.item.attachments.length))+" ")]),_vm._v(" Attachments ")],1):_vm._e()])])]),(_vm.opened)?_c('div',{staticClass:"description break-all"},[_c('div',{staticClass:"property property--description text-xs leading-5 2xl:text-sm 2xl:leading-6",domProps:{"innerHTML":_vm._s(_vm.item.html_body)}})]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }